import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      Copyright &#169; 2024 Isaac Thill. All rights reserved. 
    </footer>
  );
};

export default Footer;